import { lazy } from 'react';
import { AuthGuard } from '../components/auth/auth-guard';
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';

const Dashboard = Loadable(lazy(() => import('../views/dashboard/dashboard')));
const QuookerSpreadsheetImportForm = Loadable(lazy(() => import('../views/quooker/import-spreadsheet-form')));
const ImportSpreadsheetForm = Loadable(lazy(() => import('../views/spreadsheet/import-form')));
const CustomsRequestOverview = Loadable(lazy(() => import('../views/overview/customs-requests-overview')));
const PdfUploadForm = Loadable(lazy(() => import('../views/pdf/upload')));
const PdfUploadGptForm = Loadable(lazy(() => import('../views/pdf/upload-gpt')));

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '*',
      element: <Dashboard />,
    },
    // {
    //   path: '/',
    //   element: <Dashboard />,
    // },
    {
      path: '/customs-requests',
      element: <CustomsRequestOverview />,
    },
    {
      path: '/quooker/import',
      element: <QuookerSpreadsheetImportForm />,
    },
    {
      path: '/spreadsheet/import',
      element: <ImportSpreadsheetForm />,
    },
    {
      path: '/pdf/upload',
      element: <PdfUploadForm />,
    },
    {
      path: '/pdf/upload-gpt',
      element: <PdfUploadGptForm />,
    },
  ],
};

export default MainRoutes;