import spreadsheet from './spreadsheet';
import general from './general';
import pdf from './pdf';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    general,
    spreadsheet,
    pdf,
  ]
};

export default menuItems;
