// assets
import { IconList } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const general  = {
  id: 'general',
  title: 'Aanvragen',
  type: 'group',
  children: [
    {
      id: ' customsRequestsOverview',
      title: 'Overzicht',
      type: 'item',
      url: '/customs-requests',
      icon: IconList,
      breadcrumbs: false,
    },
  ]
};

export default general;
