import jwt_decode from 'jwt-decode';

const KEY = 'AUTH_TOKEN';

export const isTokenValid = (token: string | null): boolean => {
  if (token === null) {
    return false;
  }
  const decodedToken: any = jwt_decode(token);

  return Date.now() <= decodedToken.exp * 1000;
}

export const getToken = (): string | null => {
  return localStorage.getItem(KEY);
}

export const setToken = (token: string): void => {
  localStorage.setItem(KEY, token);
}

