import React from 'react';
import { Box, Typography, Grid, Button, Container } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { InputField } from '../../../ui-component/input-field';
import { useAuth } from '../../../hooks/use-auth';

const Login = () => {
  const { login } = useAuth();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      // email: 'info@fidocs.tax',
      password: '',
      // password: '#FiDocsGekkie#',
    },
  });

  const onSubmit = async (data: any) => {
    await login(data.email, data.password);
  };

  return (
    <Container sx={{ mt: 10 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            mb: 3,
          }}
        >
          <Typography color="textPrimary" variant="h4">
            Login
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => {
                const { onChange, onBlur, value, name } = field;
                return (

                  <InputField
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    autoFocus
                    label="Email address"
                    type="email"
                    autoComplete="off"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => {
                const { onChange, onBlur, value, name } = field;
                return (
                  <InputField
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    label="Password"
                    type="password"
                    autoComplete="off"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button color="info" fullWidth type="submit" variant="contained">
              Log In
            </Button>
          </Grid>
          <Grid item xs={12}>
            {/* <Button
            color="info"
            component={BrowserLink}
            to="/password-recovery"
            variant="text"
          >
            Forgot password
          </Button> */}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default Login;
