// assets
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconFileSpreadsheet } from '@tabler/icons';

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const spreadsheet = {
  id: 'spreadsheet',
  title: 'Excel',
  type: 'group',
  children: [
    {
      id: ' quookerSpreadsheet',
      title: 'Inlezen Quooker Excel',
      type: 'item',
      url: '/quooker/import',
      icon: IconFileSpreadsheet,
      breadcrumbs: false,
    },
    {
      id: ' spreadsheetImport',
      title: 'Inlezen Excel',
      type: 'item',
      url: '/spreadsheet/import',
      icon: IconFileSpreadsheet,
      breadcrumbs: false,
    },
  ]
};

export default spreadsheet;
