import PictureAsPdfOutlined from '@mui/icons-material/PictureAsPdfOutlined';

const pdf = {
  id: 'pdf',
  title: 'PDF',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Upload PDF',
      type: 'item',
      url: '/pdf/upload',
      icon: PictureAsPdfOutlined,
      breadcrumbs: false
    },
    {
      id: 'pdf-gpt',
      title: 'Uitlezen met AI',
      type: 'item',
      url: '/pdf/upload-gpt',
      icon: PictureAsPdfOutlined,
      breadcrumbs: false
    }
  ]
};

export default pdf;